<template>
  <div class="login-wrapper">
    <div class="bg-wrapper">
      <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="loginRules"
        class="login-form"
      >
        <img class="logo" :src="oemLogo" alt="" />
        <div class="login-title">
          欢迎来到{{ oemName + "！" || "CRM管理系统！" }}
        </div>
        <div class="login-text" v-if="loginType == 1 || loginType == 2">
          登录你的账户
        </div>
        <div class="login-text" v-if="loginType == 4">忘记密码</div>
        <div class="login-text" v-if="loginType == 3">注册账号</div>
        <template v-if="loginType == 1">
          <el-form-item style="margin-top: 16px" prop="username">
            <div class="input-tips">账号</div>
            <el-input
              type="text"
              v-model="loginForm.username"
              placeholder="请输入手机号"
            />
          </el-form-item>

          <el-form-item style="margin-top: 16px" prop="company">
            <div class="input-tips">公司</div>
            <el-select
              v-model="companyName"
              size="large"
              remote
              @focus="queryCompany(loginForm.username)"
              style="width: 100%"
              placeholder="请选择公司"
              @change="selectCompany"
            >
              <el-option
                style="width: 322px"
                v-for="item in oemLogin"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            style="margin-top: 16px"
            prop="password"
            class="pswd-box"
          >
            <div class="input-tips">密码</div>
            <el-input
              :type="readonly ? 'password' : 'text'"
              v-model="loginForm.password"
              size="large"
              style="border: none"
              placeholder="请输入密码"
              ref="password"
            >
              <div class="el-input__icon" slot="suffix">
                <img
                  src="../../assets/images/login/eye-close.svg"
                  alt=""
                  v-if="readonly"
                  @click="clickEvt"
                  class="pointer"
                />
                <img
                  src="../../assets/images/login/eye-open.svg"
                  alt=""
                  v-else
                  @click="clickEvt"
                  class="pointer"
                />
              </div>
            </el-input>
          </el-form-item>

          <div class="footer">
            <div>
              <span style="color: #c3c5c8">还没有账号？</span>
              <span
                class="pointer"
                style="color: #191919"
                @click="clearInput((loginType = 3))"
                >立即注册</span
              >
            </div>

            <span class="forget pointer" @click="clearInput((loginType = 4))"
              >忘记密码？</span
            >
          </div>

          <el-form-item style="margin-top: 30px" align="center">
            <el-button
              type="primary"
              @click="handleLogin"
              style="width: 100%"
              @keyup.enter="keyDown(e)"
              >登录</el-button
            >
          </el-form-item>
          <div class="link" @click="getCodeUrl(), (loginType = 2)">
            微信扫码登录
          </div>
        </template>
        <div v-else-if="loginType == 2" class="ac">
          <el-form-item align="center">
            <iframe
              :src="codeUrl"
              name="iframe"
              class="w300 h400"
              scrolling="no"
              frameborder="0"
            ></iframe>
            <!-- <div class="text2">请使用微信扫描上方二维码登录！</div> -->
          </el-form-item>
          <div class="link" @click="loginType = 1">账号密码登录</div>
        </div>
        <div v-else-if="loginType == 3">
          <el-form-item
            style="margin-top: 16px"
            prop="username"
            id="border-none"
            v-if="resetType == 1"
          >
            <div class="input-tips">账号</div>
            <el-input
              type="text"
              v-model="registerForm.username"
              placeholder="请输入手机号"
            />
          </el-form-item>
          <el-form-item style="margin-top: 16px" v-if="resetType == 1" prop="code">
            <div class="input-tips">短信验证码</div>
            <div class="flex ju-be">
              <el-input
                v-model="registerForm.password"
                auto-complete="new-accounts"
                size="large"
                style="border: none; margin-right: 10px; width: 240px"
                placeholder="请输入验证码"
              ></el-input>
              <el-button
                type="primary"
                :disabled="countDown !== 0 ? true : false"
                size="large"
                @click="getSendCode(registerForm.username)"
                >{{
                  countDown ? `重新发送(${countDown})` : "获取验证码"
                }}</el-button
              >
            </div>
          </el-form-item>

          <el-form-item style="margin-top: 98px">
            <el-button
              style="width: 100%"
              type="primary"
              size="large"
              @click="handleLogin('test')"
              >立即体验</el-button
            >
          </el-form-item>

          <div class="footer" v-if="resetType == 1">
            <div>
              <span style="color: #c3c5c8">已有账号，</span>
              <span
                class="pointer"
                style="color: #191919"
                @click="clearInput((loginType = 1))"
                >立即登录</span
              >
            </div>
          </div>
        </div>
        <div v-else-if="loginType == 4">
          <el-form-item style="margin-top: 16px">
            <div class="flex-a-c">
              <div
                class="flex-a-c"
                v-for="(item, index) in resetState"
                :key="index"
              >
                <div
                  class="w24 h24 br50p be6eb flex-c-c"
                  :class="
                    resetType == item.type
                      ? 'bgbule cfff'
                      : resetType > item.type
                      ? 'cA2 bgfff'
                      : ''
                  "
                  v-if="resetType <= item.type"
                >
                  {{ index + 1 }}
                </div>
                <i class="el-icon-circle-check fs12 cblue" v-else></i>
                <p
                  class="ml10 cA2"
                  :class="resetType == item.type ? 'cA1 fwbold' : ''"
                >
                  {{ item.title }}
                </p>
                <p class="mr10 ml10" v-if="index !== 2">-</p>
              </div>
            </div>
          </el-form-item>

          <!-- step1 -->
          <el-form-item
            style="margin-top: 16px"
            prop="username"
            id="border-none"
            v-if="resetType == 1"
          >
            <div class="input-tips">账号</div>
            <el-input
              type="text"
              v-model="resetForm.username"
              placeholder="请输入手机号"
            />
          </el-form-item>
          <el-form-item
            style="margin-top: 16px"
            id="border-none"
            v-if="resetType == 1"
          >
            <div class="input-tips">公司</div>
            <el-select
              v-model="companyName"
              size="large"
              remote
              @focus="queryCompany(resetForm.username)"
              auto-complete="new-accounts"
              style="width: 100%"
              placeholder="请选择公司"
              @change="selectCompany"
            >
              <el-option
                style="width: 322px"
                v-for="item in oemLogin"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            style="margin-top: 16px"
            id="border-none"
            v-if="resetType == 1"
             prop="code"
          >
            <div class="input-tips">图形验证码</div>
            <div class="flex ju-be">
              <el-input
                v-model="resetForm.imgCode"
                auto-complete="new-accounts"
                size="large"
                style="border: none; margin-right: 10px; width: 240px"
                placeholder="图形验证码"
                prefix-icon="el-icon-lock"
              ></el-input>
              <img
                :src="imgCode"
                v-if="imgCode"
                @click="showResetUserInfo"
                class="graph w100 h40 pointer"
              />
              <div class="bgf7fa w100 h40" v-else></div>
            </div>
          </el-form-item>
          <el-form-item
            style="margin-top: 16px"
            id="border-none"
            v-if="resetType == 1"
             prop="code"
          >
            <div class="input-tips">短信验证码</div>
            <div class="flex ju-be">
              <el-input
                v-model="resetForm.messageCode"
                auto-complete="new-accounts"
                size="large"
                style="border: none; margin-right: 10px; width: 240px"
                placeholder="请输入验证码"
                prefix-icon="el-icon-lock"
              ></el-input>
              <el-button
                type="primary"
                :disabled="resetCountDown !== 0 ? true : false"
                size="large"
                @click="getResetSendCode(resetForm.username)"
                >{{
                  resetCountDown ? `重新发送(${resetCountDown})` : "获取验证码"
                }}</el-button
              >
            </div>
          </el-form-item>

          <!-- step2 -->
          <el-form-item
            style="margin-top: 72px"
            prop="password"
            id="border-none"
            v-if="resetType == 2"
          >
            <div class="input-tips">设置新密码</div>
            <el-input
              type="password"
              v-model="resetPwdForm.password"
              size="large"
              style="border: none"
              placeholder="请输入新密码"
              ref="password"
              @focus="changAttr($event, 'focus')"
              @blur="changAttr($event, 'blur')"
              @click.native="clickEvt"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            style="margin-top: 16px"
            prop="password"
            id="border-none"
            v-if="resetType == 2"
          >
            <div class="input-tips">再次输入密码</div>
            <el-input
              type="password"
              v-model="resetPwdForm.secondPassword"
              size="large"
              style="border: none"
              placeholder="请再次输入密码"
              ref="password"
            >
            </el-input>
          </el-form-item>

          <el-form-item
            style="margin-top: 113px"
            id="border-none"
            v-if="resetType == 3"
          >
            <div class="flex-c-c flex-column">
              <i
                class="el-icon-success cblue"
                style="font-size: 100px; color: #12222b"
              ></i>
              <p class="cA1 fs7">设置成功!</p>
            </div>
          </el-form-item>

          <el-form-item style="margin-top: 30px" v-if="resetType == 1">
            <el-button
              type="primary"
              style="width: 100%"
              size="large"
              @click="checkNumber"
            >
              下一步
            </el-button>
          </el-form-item>
          <el-form-item style="margin-top: 130px" v-else-if="resetType == 2">
            <el-button
              style="width: 100%"
              type="primary"
              size="large"
              @click="changePassword"
              >下一步</el-button
            >
          </el-form-item>

          <el-form-item style="margin-top: 134px" v-if="resetType == 3">
            <el-button
              style="width: 100%"
              type="primary"
              size="large"
              @click="clearInput((loginType = 1), (resetType = 1))"
              >立即登录</el-button
            >
          </el-form-item>

          <div class="footer" v-if="resetType == 1">
            <div>
              <span style="color: #c3c5c8">还没有账号？</span>
              <span
                class="pointer"
                style="color: #191919"
                @click="clearInput((loginType = 3), (resetType = 1))"
                >立即注册</span
              >
            </div>

            <span
              class="forget pointer"
              @click="clearInput((loginType = 1), (resetType = 1))"
              >返回登录>></span
            >
          </div>

          <div class="footer" v-if="resetType == 2">
            <span
              class="forget pointer"
              style="right: 0"
              @click="clearInput((loginType = 1), (resetType = 1))"
              >返回上一步>></span
            >
          </div>
        </div>
      </el-form>
      <img v-if="loginBlockImg" :src="loginBlockImg" class="login-img" alt="" />
      <img
        v-else
        src="@/assets/images/login/login.png"
        class="login-img"
        alt=""
      />
    </div>

    <p class="bottomText">
      {{ loginBottomInfo || "" }}
      <a
        target="_blank"
        style="color: rgb(72, 72, 72)"
        href="https://beian.miit.gov.cn/#/Integrated/index"
        >{{ loginDomainRecordNum || "" }}</a
      >
    </p>
  </div>
</template>

<script>
import api from "@/api/login";
import { getDataTimeSec } from "@/utils/index";
import { queryOemDomain } from "@/api/oemConfig.js";

export default {
  name: "login",

  data() {
    return {
      loginBottomInfo: "", //页面底部信息
      loginDomainRecordNum: "", //域名
      loginBlockImg: "", //登录框左侧图片
      oemName: "", //公司名称
      oemLogo: "", //公司logo
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '用户名不能为空' }],
        password: [{ required: true, trigger: 'blur', message: '密码不能为空' }],
        code: [{ required: true, trigger: 'blur', message: '验证码不能为空' }],
        company: [{ required: true, trigger: 'change', message: '请选择企业' }],
      },
      showLogin: "none",
      registerForm: {
        username: "",
        password: "",
        companyName: "",
        companyId: "",
      },
      resetForm: {
        username: "",
        imgCode: "",
        messageCode: "",
      },
      resetPwdForm: {
        password: "",
        secondPassword: "",
      },
      companyName: "", // 公司名
      oemLogin: [],
      loginType: 1, //登录方式 1为账号密码登录  2为 扫码登录  3为 体验账号登录 4为 忘记密码
      companyId: "",
      codeUrl: "",
      resetType: 1, //修改密码方式 1为手机号验证  2为 设置新密码  3为 成功
      imgCode: "",
      signature: "", //认证签名
      resetState: [
        {
          title: "安全验证",
          type: 1,
        },
        {
          title: "设置新密码",
          type: 2,
        },
        {
          title: "设置成功",
          type: 3,
        },
      ],
      countDown: 0,
      resetCountDown: 0,
      readonly: true,
    };
  },
  async created() {
    this.getUserInfo();
    this.showResetUserInfo();

    // const oemConfig = await queryOemDomain({
    //   oemDomain: document.domain || "crm.jquen.com",
    //   companyId: 2
    // });
    let urls = document.domain || "crm.jquen.com";
    console.log(urls, "请求域名----");
    const oemConfig = await queryOemDomain({ oemDomain: urls });
    if (!oemConfig) {
      this.$message.error("oem配置错误,请联系售后");
      return;
    }
    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";

    if (oemConfig && oemConfig.data) {
      localStorage.setItem("oem_info", JSON.stringify(oemConfig.data));
      link.href = oemConfig.data.iconLogo;
      document.getElementsByTagName("head")[0].appendChild(link);
      //获取网页标题
      document.title = oemConfig.data.oemName;
      if (
        oemConfig.data.loginBackgroundImg &&
        oemConfig.data.loginBackgroundImg !== ""
      ) {
        document.getElementsByClassName(
          "login-wrapper"
        )[0].style.backgroundImage =
          "url(" + oemConfig.data.loginBackgroundImg + ")";
      } else {
        document.getElementsByClassName(
          "login-wrapper"
        )[0].style.backgroundImage =
          "url(https://cdn-oss.jquen.com/crm-pc/login/login_bg.png)";
      }
      this.oemName = oemConfig.data.oemName;
      this.oemLogo = oemConfig.data.oemLogo;

      this.loginDomainRecordNum = oemConfig.data.loginDomainRecordNum;
      this.loginBottomInfo = oemConfig.data.loginBottomInfo;

      if (oemConfig.data.loginBlockImg && oemConfig.data.loginBlockImg !== "") {
        this.loginBlockImg = oemConfig.data.loginBlockImg;
      } else {
        this.loginBlockImg =
          "https://cdn-oss.jquen.com/crm-pc/login/left-img.png";
      }
    } else {
      link.href = "";
      document.getElementsByTagName("head")[0].appendChild(link);
      //获取网页标题
      document.title = "CRM系统准备中";
    }
  },
  mounted() {
    // 绑定监听事件
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },

  methods: {
    //密码的历史记录
    getHistory(queryString, cb) {
      let $cname = document.querySelector(".showAutocomplete");
      let history = JSON.parse(localStorage.getItem("password")),
        arr = [];
      if (history) {
        history.forEach((v, i) => {
          arr.push({
            value: v.value.username,
            password: v.value.password,
          });
        });
      }
      console.log($cname);
      arr.length == 0 ? ($cname.style.display = "none") : "";
      cb(arr);
    },
    // 查询公司
    queryCompany(val) {
      if (
        !/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(
          val
        )
      ) {
        this.loginForm.username = "";
        this.resetForm.username = "";
        this.$message({
          title: "警告",
          message: "请输入正确的电话号码",
          type: "warning",
        });
        return;
      }
      api.fetchCompany({ phone: val }, { showLoading: false }).then((res) => {
        console.log(res);
        this.oemLogin = res.data;
      });
    },
    getSendCode(phone) {
      if (this.countDown) return;
      if (
        !/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(
          phone
        )
      ) {
        phone = "";
        this.$message({
          title: "警告",
          message: "请输入正确的电话号码",
          type: "warning",
        });
        return;
      }
      api.fetchCompany({ phone: phone }, { showLoading: false }).then((res) => {
        this.countDown = 60;
        this.timer = setInterval(() => {
          this.countDown--;
          if (this.countDown === 0) {
            clearInterval(this.timer);
          }
        }, 1 * 1000);
        console.log(res);
        this.registerForm.companyId = res.data[0].companyId;
        this.registerForm.companyName = res.data[0].companyName;
      });
      api
        .fetchVeriCode({ phone: phone, type: 1 }, { showLoading: false })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },

    getResetSendCode(phone) {
      if (this.resetCountDown) return;
      if (
        !/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(
          phone
        )
      ) {
        phone = "";
        this.$message({
          title: "警告",
          message: "请输入正确的电话号码",
          type: "warning",
        });
        return;
      }
      api.fetchCompany({ phone: phone }, { showLoading: false }).then((res) => {
        this.resetCountDown = 60;
        this.timer = setInterval(() => {
          this.resetCountDown--;
          if (this.resetCountDown === 0) {
            clearInterval(this.timer);
          }
        }, 1 * 1000);
        console.log(res);
        this.registerForm.companyId = res.data[0].companyId;
        this.registerForm.companyName = res.data[0].companyName;
      });
      api
        .fetchVeriCode({ phone: phone, type: 1 }, { showLoading: false })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },

    getUserInfo() {
      // let data = {
      //   code: "091LE0100dV9sL1JAp100a9sAR3LE01i",
      //   state: 'one-stand'
      // }
      // api.pcLoginCallBack(data)
      //   .then(res => {
      //     console.log(res)
      //   })
    },
    //获取验证码
    getCodeUrl() {
      api
        .fetchCodeUrl()
        .then((res) => {
          this.codeUrl = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 选择公司
    selectCompany(val) {
      this.companyId = val;
    },
    //获取图形验证码
    showResetUserInfo() {
      let data = {
        phone: this.resetForm.username || "",
      };
      api
        .getVerifiCode(data)
        .then((res) => {
          console.log();
          this.imgCode = "data:image/png;base64," + res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkNumber() {
      if (this.oemLogin[0].companyId == 1) {
        this.$message.error(
          "此手机号未注册，请输入已注册手机号或点击免费注册体验可免费体验查看"
        );
        return;
      }
      if (
        !this.resetForm.username ||
        !this.resetForm.imgCode ||
        !this.resetForm.messageCode
      ) {
        this.$message({
          message: "手机号或验证码为空",
          type: "error",
        });
        return;
      }
      if (
        !/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(
          this.resetForm.username
        )
      ) {
        this.loginForm.username = "";
        this.$message({
          title: "警告",
          message: "请输入正确的电话号码",
          type: "warning",
        });
        return;
      }
      let data = {
        imgCode: this.resetForm.imgCode,
        smsCode: this.resetForm.messageCode,
        phone: this.resetForm.username,
      };
      api
        .forgetPwd(data)
        .then((res) => {
          this.showResetUserInfo();
          this.signature = res.data;
          this.resetType = 2;
        })
        .catch((err) => {
          if (err.code == 201) {
            this.$message({
              title: "警告",
              message: err.message,
              type: "warning",
            });
          }
        });
    },
    changePassword() {
      if (
        this.resetPwdForm.password.length < 6 ||
        this.resetPwdForm.secondPassword.length < 6
      ) {
        this.$message({
          message: "密码长度不得小于六位",
          type: "warning",
        });
        return;
      }
      if (this.resetPwdForm.password !== this.resetPwdForm.secondPassword) {
        this.$message({
          message: "两次输入的密码不一，请重新输入",
          type: "warning",
        });
        return;
      }
      let data = {
        signature: this.signature,
        password: this.resetPwdForm.password,
        companyId: this.companyId,
      };
      api
        .updatePwd(data)
        .then((res) => {
          console.log(res);
          this.resetType = 3;
        })
        .catch((err) => {
          if (err.code == 201) {
            this.$message({
              message: err.message,
              type: "warning",
            });
            return;
          }
        });
    },
    // 清除登录数据
    clearInput() {
      this.loginForm = {
        username: "",
        password: "",
      };
      this.registerForm = {
        username: "",
        password: "",
        companyName: "",
        companyId: "",
      };
      this.resetForm = {
        username: "",
        imgCode: "",
        messageCode: "",
      };
      this.resetPwdForm = {
        password: "",
        secondPassword: "",
      };
      this.companyName = "";
    },
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode == 13) {
        this.handleLogin(); // 定义的登录方法
      }
    },
    // 登录请求
    handleLogin(type) {
      //登录时间
      let date = new Date();
      date = getDataTimeSec(date);
      let value = "";
      if (type !== "test") {
        //非体验用户登录
        value = {
          username: this.loginForm.username,
          phone: this.loginForm.username,
          password: this.loginForm.password,
          companyName: this.companyName,
          companyId: this.companyId,
        };
      } else {
        //体验用户登录
        value = {
          username: this.registerForm.username,
          phone: this.registerForm.username,
          // password: this.loginForm.password,
          smsCode: this.registerForm.password,
          companyName: this.registerForm.companyName,
          companyId: this.registerForm.companyId,
        };
      }

      let than = this,
        passwordArr = [], //
        text = "password";

      localStorage.removeItem("password");
      passwordArr.push({ value });
      console.log(value, "9999");

      if (JSON.parse(localStorage.getItem(text))) {
        // 判断是否已有xxx查询记录的localStorage
        if (localStorage.getItem(text).indexOf(value.username) > -1) {
          // 判断是否已有此条查询记录，若已存在，则修改顺序
          let userArr = JSON.parse(localStorage.getItem(text));
          console.log(userArr);
          userArr.forEach((v, k) => {
            console.log(v);
            if (v.value.username == value.username) {
              //将重复的username顺序提前
              let currentUser = userArr.splice(k, 1);
              userArr.unshift(currentUser[0]);
              localStorage.setItem(text, JSON.stringify(userArr));
            }
          });
        } else if (JSON.parse(localStorage.getItem(text)).length >= 5) {
          //记录大于五条时删除最后一条添加新的记录
          let arr = JSON.parse(localStorage.getItem(text));
          arr.pop();
          arr.unshift(passwordArr[0]);
          localStorage.setItem(text, JSON.stringify(arr));
        } else {
          //没有记录且不大于五条记录
          localStorage.setItem(
            text,
            JSON.stringify(
              passwordArr.concat(JSON.parse(localStorage.getItem(text)))
            )
          );
        }
      } else {
        // 首次创建
        localStorage.removeItem("password");
        localStorage.setItem(text, JSON.stringify(passwordArr));
      }
      if (type !== "test") {
        //手机密码登录
        if (value.companyId == 1) {
          this.$message.error(
            "此手机号未注册，请输入已注册手机号或点击免费注册体验可免费体验查看"
          );
          return;
        }
        if (!value.username || !value.companyName || !value.password) {
          this.$message.error("账号信息不完整，请填写完整");
          return;
        }

        api
          .fetchLoginByPhone(value)
          .then((res) => {
            if (res.code === 200) {
              //存储登录用户信息
              this.$store.state.loginRoot.loginDate = res.data.lastLoginTime;
              this.$store.state.loginRoot.userInfo = res.data;
              if (res.data.roleId == res.data.companyId) {
                localStorage.setItem("isBoss", "true");
              } else {
                localStorage.setItem("isBoss", "false");
              }
              localStorage.setItem("info", JSON.stringify(res.data));
              localStorage.setItem("loginDate", res.data.lastLoginTime);
              //存储token
              localStorage.setItem("token", res.data.token);
              this.$router.push("/dashboard");
            } else {
              this.$message.error(res.data);
              return;
            }
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      } else {
        //体验账号登录
        if (!value.username || !value.smsCode) {
          this.$message.error("账号信息不完整，请填写完整");
          return;
        }
        api
          .fetchLogin(value)
          .then((res) => {
            if (res.code === 200) {
              //存储登录用户信息
              this.$store.state.loginRoot.loginDate = date;
              this.$store.state.loginRoot.userInfo = res.data;
              if (res.data.roleId == res.data.companyId) {
                localStorage.setItem("isBoss", "true");
              } else {
                localStorage.setItem("isBoss", "false");
              }
              localStorage.setItem("info", JSON.stringify(res.data));
              localStorage.setItem("loginDate", res.data.lastLoginTime);
              //存储token
              localStorage.setItem("token", res.data.token);
              this.$router.push("/dashboard");
            } else {
              this.$message.error(res.data);
              return;
            }
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      }
    },

    changAttr(e, type) {
      // if (type === "focus") {
      //   if (e) {
      //     e.stopPropagation();
      //     e.preventDefault();
      //   }
      //   setTimeout(() => {
      //     this.readonly = false;
      //   }, 100);
      // } else {
      //   if (e) {
      //     e.stopPropagation();
      //   }
      //   this.readonly = true;
      // }
    },
    clickEvt() {
      // if (this.$refs.password) {
      //   this.$refs.password.$refs.input.onmousedown = (evt) => {
      //     if (evt) {
      //       evt.preventDefault();
      //       evt.stopPropagation();
      //     }
      //     if (this.loginForm.password === "" || this.readonly) {
      //       this.$refs.password.$refs.input.blur();
      //       setTimeout(() => {
      //         this.$refs.password.$refs.input.focus();
      //       }, 0);
      //     }
      //     return false;
      //   };
      // }
      this.readonly = !this.readonly;
    },
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}

.login-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  // background-image: url("../../assets/images/login/login_bg.png");
  background-size: cover;
  display: flex;
  flex-direction: row;
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.bg-wrapper {
  width: 80%;
  max-width: 1240px;
  height: 85%;
  min-height: 700px;
  background: #ffffff;
  border-radius: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  .login-form {
    width: 50%;
    padding: 0 calc((50% - 360px) / 2);
    .logo {
      position: absolute;
      height: 28px;
      left: 32px;
      top: 20px;
      width: 135px;
      height: auto;
    }
    .login-title {
      font-size: 16px;
      color: #666666;
    }
    .login-text {
      font-size: 32px;
      font-weight: 700;
      color: #1d2129;
      margin: 26px auto 50px;
    }
    .link {
      color: #0051ff;
      font-size: 14px;
      margin-top: 16px;
      text-align: left;
      cursor: pointer;
    }
  }
  .el-input__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }
  .login-img {
    padding: 20px;
    height: 100%;
    // border-radius: 40px;
  }
  .footer {
    width: 100%;
    font-size: 12px;
    line-height: 18px;
    color: #3d3c49;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.bottomText {
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 32px;
  left: 0;
  font-size: 12px;
  // color: #ffffff;
  opacity: 0.7;
}
</style>
